import React, { useEffect, useState, lazy, MouseEvent, Suspense, forwardRef } from 'react'
import UserService from '../../services/UserService'
import { useRecoilValue } from 'recoil'
import { subframePositionStartAtom, motorControlAtom, machineNameAtom } from '../../_state'
import { NotificationIndicator, GitLabCreateTicketLink, ServiceStoppedIndicator } from '..'
import { MSUIcon } from '../../msu/MSUIcon'
import {
    Box,
    Toolbar,
    IconButton,
    Typography,
    AppBar,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DirectionsIcon from '@mui/icons-material/Directions'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import InfoIcon from '@mui/icons-material/Info'
import TerminalIcon from '@mui/icons-material/Terminal'
import CameraIcon from '@mui/icons-material/Camera'
import BiotechIcon from '@mui/icons-material/Biotech'
import OpenWithIcon from '@mui/icons-material/OpenWith'
import { styles } from '../../styles'
import AlertDialogSlide from '../AlertDialogSlide'
import { useAlertActions, useMachineActions } from '../../_actions'
import { NavLink, useLocation, useNavigate, useResolvedPath, Link } from 'react-router-dom'
import { ReactComponent as GrafanaIcon } from '../../assets/images/grafana.svg'
import { ReactComponent as CreateTicketIcon } from '../../assets/images/create_ticket.svg'
import { ReactComponent as TicketsIcon } from '../../assets/images/tickets.svg'
import { ReactComponent as AcceptanceTestReportIcon } from '../../assets/images/acceptance_test_report.svg'
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna'
import { LogbookIndicator } from '../../logbook/LogbookIndicator'
import { TreatmentDoseAlert, TransferMachineIcon } from '../'
import { SvgIconWrapper } from '../icons/SvgIconWrapper'
import { handleOpenDirections } from '../../_helpers'
import StraightenIcon from '@mui/icons-material/Straighten'

const UltrasonicSensorStatus = lazy(() => import('../../ultrasonic/'))

const MenuMore = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const url = useResolvedPath('').pathname
    const navigate = useNavigate()
    const open = Boolean(anchorEl)
    const gps = useRecoilValue(subframePositionStartAtom)
    const machineName = useRecoilValue(machineNameAtom)
    const alertActions = useAlertActions()
    const machineActions = useMachineActions()
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)

    if (!machineName) return
    return (
        <>
            <IconButton
                aria-label='more'
                id='long-button'
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleClick}
                size='large'
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <div>
                    {UserService.hasRole(['admin', 'manufacturer']) && (
                        <>
                            <MenuItem
                                onClick={() => {
                                    handleClose()
                                    navigate('system-commands')
                                }}
                            >
                                <ListItemIcon>
                                    <TerminalIcon fontSize='small' />
                                </ListItemIcon>
                                <ListItemText>System commands</ListItemText>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleClose()
                                    navigate('jobs')
                                }}
                            >
                                <ListItemIcon>
                                    <InfoIcon fontSize='small' />
                                </ListItemIcon>
                                <ListItemText>Job History</ListItemText>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleClose()
                                    navigate('focus-cameras')
                                }}
                            >
                                <ListItemIcon>
                                    <CameraIcon fontSize='small' />
                                </ListItemIcon>
                                <ListItemText>Focus Cameras</ListItemText>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleClose()
                                    navigate('cabinets-test-bench/main-menu')
                                }}
                            >
                                <ListItemIcon>
                                    <BiotechIcon fontSize='small' />
                                </ListItemIcon>
                                <ListItemText>Cabinets Test Bench</ListItemText>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleClose()
                                    navigate('transfer')
                                }}
                            >
                                <ListItemIcon>
                                    <TransferMachineIcon color='secondary' fontSize='small' />
                                </ListItemIcon>
                                <ListItemText>Transfer</ListItemText>
                            </MenuItem>
                        </>
                    )}
                    {UserService.hasRole(['admin', 'manufacturer']) && (
                        <>
                            <MenuItem
                                onClick={() => {
                                    handleClose()
                                    navigate('robot-current-test')
                                }}
                            >
                                <ListItemIcon>
                                    <OpenWithIcon fontSize='small' />
                                </ListItemIcon>
                                <ListItemText>Robot Current Test</ListItemText>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    alertActions.info(`Opening a new tab with the tickets of ${machineName}`)
                                    window.open(
                                        `https://gitlab.com/hll-robotics/tickets/-/issues/?sort=created_date&state=opened&label_name=${machineName}&first_page=50`,
                                    )
                                }}
                            >
                                <ListItemIcon>
                                    <SvgIconWrapper>
                                        <TicketsIcon color='secondary' fontSize='small' />
                                    </SvgIconWrapper>
                                </ListItemIcon>
                                <ListItemText>List tickets</ListItemText>
                            </MenuItem>
                            <MenuItem>
                                <ListItemIcon>
                                    <SvgIconWrapper>
                                        <CreateTicketIcon color='secondary' fontSize='small' />
                                    </SvgIconWrapper>
                                </ListItemIcon>
                                <ListItemText>
                                    <GitLabCreateTicketLink machineName={machineName} />
                                </ListItemText>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    const now = Date.now()
                                    const unixTimestampNow = Math.floor(now)
                                    const fifteenMinutesAgo = Math.floor(now - 15 * 60 * 1000)
                                    const grafanaCustomerSupportUrl = `https://grafana.h2lselector.com/d/wn0td5xVk/customer-support?orgId=1&var-machine=${machineName}_mirror&from=${fifteenMinutesAgo}&to=${unixTimestampNow}`
                                    window.open(grafanaCustomerSupportUrl)
                                }}
                            >
                                <ListItemIcon>
                                    <SvgIconWrapper>
                                        <GrafanaIcon />
                                    </SvgIconWrapper>
                                </ListItemIcon>
                                <ListItemText>Grafana</ListItemText>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleClose()
                                    machineActions
                                        .getIP(machineName)
                                        .then((ip) => window.open(`http://${ip}`, '_blank'))
                                }}
                            >
                                <ListItemIcon>
                                    <SettingsInputAntennaIcon fontSize='small' />
                                </ListItemIcon>
                                <ListItemText>Teltonika Admin UI</ListItemText>
                            </MenuItem>
                        </>
                    )}
                </div>
                <MenuItem
                    onClick={() => {
                        handleClose()
                        navigate(`${url}/acceptance-tests`)
                    }}
                >
                    <ListItemIcon>
                        <SvgIconWrapper>
                            <AcceptanceTestReportIcon color='secondary' fontSize='small' />
                        </SvgIconWrapper>
                    </ListItemIcon>
                    <ListItemText>Acceptance Tests</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose()
                        navigate(`${url}/adjust-bed-alignment`)
                    }}
                >
                    <ListItemIcon>
                        <SvgIconWrapper>
                            <StraightenIcon color='secondary' fontSize='small' />
                        </SvgIconWrapper>
                    </ListItemIcon>
                    <ListItemText>Bed Alignment</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        if (!gps || !gps.lat || !gps.lon) return
                        handleOpenDirections(gps.lat, gps.lon)
                    }}
                >
                    <ListItemIcon>
                        <DirectionsIcon color='secondary' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Directions</ListItemText>
                </MenuItem>
            </Menu>
        </>
    )
}

const EmoWarningIndicator = () => {
    const motorControl = useRecoilValue(motorControlAtom)
    const [emoVisible, setEmoVisible] = useState(false)

    useEffect(() => {
        if (motorControl?.machine_state === 2) {
            setEmoVisible(true)
        } else {
            setEmoVisible(false)
        }
    }, [motorControl])

    if (emoVisible) {
        return <AlertDialogSlide title={'Warning!'} description={'Emergency button is enabled'} />
    } else {
        return null
    }
}

MachineViewAppBar.displayName = 'MachineViewAppBar'

export default function MachineViewAppBar() {
    // console.log('[MachineViewAppBar] Render')
    const location = useLocation()
    const navigate = useNavigate()
    const url = useResolvedPath('').pathname

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const LinkMachines = forwardRef((props, ref) => (
        <NavLink
            style={(isActive) => ({
                color: isActive ? '#fff' : '#b7b7b7',
            })}
            to='/machines'
            {...props}
            role={undefined}
        />
    ))

    const goBack = () => {
        navigate('/machines')
    }

    LinkMachines.displayName = 'LinkMachines'

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position='static'>
                    <Toolbar disableGutters>
                        <IconButton color='inherit' aria-label='back' onClick={goBack}>
                            <ArrowBackIcon />
                        </IconButton>
                        {/* {UserService.hasRole(['dev']) ? (
                        <Badge
                                badgeContent={
                                    miscellaneousState === 'error'
                                    // && miscellaneousState !== null ? ' ' : null
                                }
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                            color='error'
                        >
                            <Typography variant='h5' noWrap={true} color='secondary'>
                            {location.pathname.split('/')[2]}
                        </Typography>
                        </Badge>
                        ) : ( */}
                        <Typography variant='h5' noWrap={true} color='secondary'>
                            {location.pathname.split('/')[2]}
                        </Typography>
                        {/* )} */}
                        <Box sx={[styles.MachineViewAppBar.FlexGrowBox]} />
                        <Box sx={[styles.MachineViewAppBar.ContentBox]}>
                            <ServiceStoppedIndicator />
                            <EmoWarningIndicator />
                            <Suspense fallback={null}>
                                <UltrasonicSensorStatus />
                            </Suspense>
                            <TreatmentDoseAlert />
                            <MSUIcon />
                            <LogbookIndicator handleOpenModal={() => navigate(`${url}/logbook`)} />
                            <NotificationIndicator />
                            <MenuMore />
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
        </>
    )
}
