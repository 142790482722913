import { useRecoilValue } from 'recoil'
import LoadingButton from '@mui/lab/LoadingButton'
import RouteIcon from '@mui/icons-material/Route'
import { groupIDsToBedIDsSelector } from '../_state'
import { HandleCreateTask } from '../types'

export { CreateTaskButton }

function CreateTaskButton({
    btnLoading,
    handleCreateTask,
}: {
    btnLoading: boolean
    handleCreateTask: HandleCreateTask
}) {
    const selectedBedIDs = useRecoilValue(groupIDsToBedIDsSelector)

    return (
        <LoadingButton
            disabled={selectedBedIDs.length === 0}
            loading={btnLoading}
            loadingPosition='start'
            startIcon={<RouteIcon />}
            key='create-task'
            sx={{ p: 1 }}
            variant='contained'
            size='large'
            onClick={() => {
                handleCreateTask(selectedBedIDs)
            }}
            fullWidth
        >
            Create task
        </LoadingButton>
    )
}
