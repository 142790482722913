import React from 'react'
import {
    h2lFieldJsonAtom,
    mapAtom,
    taskAtom,
    selectedGroupIDsAtom,
    drawSettingsAtom,
    taskSettingsState,
} from '../_state'
import { useRecoilValue } from 'recoil'
import config from '../config.json'
import { useAlertActions } from '../_actions'
import {
    _drawPlantedArea,
    zoomMapLayer,
    addFeaturesToMap,
    addStyleFromGeoJSON,
    removeFeaturesFromMap,
    useGroupIDsSelector,
} from '../_helpers'
import { H2LFieldJson, BedGeoJSON, TaskSettings } from '../types'

const startSymbol = {
    //path: 'M -2,-2 2,2 M 2,-2 -2,2',
    path: 'M -2,0 0,-2 2,0 0,2 z',
    strokeColor: '#292',
    fillColor: '#292',
    fillOpacity: 1,
    scale: 4,
}
const endSymbol = {
    path: 'M -2,-2 2,2 M 2,-2 -2,2', // X icon
    strokeColor: '#ffa500',
    fillColor: '#ffa500',
    fillOpacity: 1,
    scale: 4,
}

function createArrow(coordinates, map, type: string) {
    let color, offset
    if (type == 'begin') {
        color = 'green'
        offset = '0%'
    } else {
        color = 'orange'
        offset = '100%'
    }
    const arrowPath = new google.maps.Polyline({
        path: coordinates.map((coords) => ({
            lat: coords[1],
            lng: coords[0],
        })),
        strokeOpacity: 0,
        icons: [
            {
                icon: {
                    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    strokeColor: 'white',
                    strokeWeight: 1.0,
                    strokeOpacity: 1.0,
                    fillOpacity: 1.0,
                    fillColor: color,
                    scale: 4,
                },
                offset: offset,
            },
        ],
        zIndex: 5,
        map,
    })

    return arrowPath
}

function createTaskLine(coordinates, map, name) {
    const arrowSymbol = {
        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        strokeColor: '#ffffff',
        fillColor: '#0000FF',
        fillOpacity: 1,
        strokeWeight: 1,
        scale: 3,
    }
    const taskPolyline = new window.google.maps.Polyline({
        strokeColor: 'white',
        strokeWeight: config.drawing.task.STROKE_WEIGHT,
        path: coordinates,
        icons:
            name === 'turn'
                ? []
                : [
                      {
                          icon: arrowSymbol,
                          repeat: '350px',
                          offset: '50%',
                      },
                  ],
        map,
        zIndex: config.drawing.task.Z_INDEX,
    })
    return taskPolyline
}

function calculateBeginAndEndArrows(
    field: H2LFieldJson,
    filteredBeds: BedGeoJSON[],
    taskSettings: TaskSettings,
): { begin: { lat: number; lng: number }[]; end: { lat: number; lng: number }[] } {
    let begin: [number, number][] = []
    let end: [number, number][] = []
    const minRow = Math.min(...filteredBeds.map((bed) => bed.properties.row_number))
    const maxRow = Math.max(...filteredBeds.map((bed) => bed.properties.row_number))
    const rowNumbersSet = new Set(filteredBeds.map((bed) => bed.properties.row_number))

    const condition1 = rowNumbersSet.size % 2 === 0
    const condition2 = (maxRow - Math.min(...rowNumbersSet)) % 2 === 0
    const condition3 = taskSettings.direction === 'up'
    const combinationsDict = {
        '00': false, //yes yes
        '01': true, //yes yes
        '10': true, //yes yes
        '11': false, //yes yes
    }

    const res = combinationsDict[`${condition1 ? 1 : 0}${condition2 ? 1 : 0}`]
    const result = condition3 ? res : !res

    if (result) {
        end = field.beds
            .filter((bed) => bed.properties.row_number === maxRow)
            .sort((a, b) => b.properties.row_order - a.properties.row_order)[0].geometry.coordinates
    } else {
        end = field.beds
            .filter((bed) => bed.properties.row_number === maxRow && bed.properties.row_order === 1)[0]
            .geometry.coordinates.slice()
            .reverse()
    }
    if (taskSettings.direction == 'up') {
        begin = field.beds.filter((bed) => bed.properties.row_number === minRow && bed.properties.row_order === 1)[0]
            .geometry.coordinates
    } else {
        begin = field.beds
            .filter((bed) => bed.properties.row_number === minRow)
            .sort((a, b) => b.properties.row_order - a.properties.row_order)[0]
            .geometry.coordinates.slice()
            .reverse()
    }

    if (taskSettings.repeat) {
        end = [...begin].reverse() // Create new array, then reverse
    }
    return {
        begin: begin.map((coords) => ({ lat: coords[1], lng: coords[0] })),
        end: end.map((coords) => ({ lat: coords[1], lng: coords[0] })),
    }
}

export function CropTask(): null {
    // console.log('[CropTask] Render')
    const map = useRecoilValue(mapAtom)
    const drawSettings = useRecoilValue(drawSettingsAtom)
    const taskSettings = useRecoilValue(taskSettingsState)
    const alerts = useAlertActions()
    const mp = React.useRef({ crop_id: null, data: new google.maps.Data() })
    const task_checks = React.useRef(new google.maps.Data())
    const startStop = React.useRef({
        begin: createArrow([], map, 'begin'),
        end: createArrow([], map, 'end'),
    })
    addStyleFromGeoJSON(task_checks.current)
    const tulipBedSelector = useGroupIDsSelector()
    const selectedGroupIDs = useRecoilValue(selectedGroupIDsAtom)
    const field = useRecoilValue(h2lFieldJsonAtom)
    const task = useRecoilValue(taskAtom)

    const lines = []
    React.useEffect(() => {
        // Handle Highlighting
        mp.current.data.forEach((feature) => {
            if (selectedGroupIDs.has(feature.getProperty('group_id'))) {
                mp.current.data.overrideStyle(feature, {
                    strokeWeight: config.drawing.tulip_bed.HIGHLIGHT_STROKE_WEIGHT,
                    strokeOpacity: config.drawing.tulip_bed.HIGHLIGHT_STROKE_OPACITY,
                })
            } else {
                mp.current.data.overrideStyle(feature, {
                    strokeWeight: config.drawing.tulip_bed.DEFAULT_STROKE_WEIGHT,
                    strokeOpacity: config.drawing.tulip_bed.DEFAULT_STROKE_OPACITY,
                })
            }
        })

        // Handle BeginEnd Indicators
        if (selectedGroupIDs.size && field) {
            const filteredBeds = field.beds.filter(
                (bed) => selectedGroupIDs.has(bed.properties.group_id) && !bed.properties.skip,
            )
            if (filteredBeds.length) {
                // no filtered beds if everything is skipped
                const { begin, end } = calculateBeginAndEndArrows(field, filteredBeds, taskSettings)

                startStop.current.begin.setPath(begin)
                startStop.current.end.setPath(end)

                startStop.current.begin.setMap(map)
                startStop.current.end.setMap(map)
            } else {
                startStop.current.begin.setMap(null)
                startStop.current.end.setMap(null)
            }
        } else {
            startStop.current.begin.setMap(null)
            startStop.current.end.setMap(null)
        }
    }, [selectedGroupIDs, taskSettings])

    // Add listener for selecting features
    const clickBeds = React.useCallback(
        function (event) {
            if (event.feature.getProperty('name')) {
                tulipBedSelector.add(event.feature.getProperty('group_id'))
            }
        },
        [map, selectedGroupIDs],
    )
    React.useEffect(() => {
        mp.current.data.addListener('click', clickBeds)
        return () => {
            window.google.maps.event.clearListeners(mp.current.data, 'click')
        }
    }, [clickBeds])

    React.useEffect(() => {
        if (map && !field) {
            //console.log('field null')
            removeFeaturesFromMap(mp.current.data)
            mp.current.data.setMap(null)
        }
        if (field && map) {
            //if (mp.current.crop_id != field.crop_id) {
            //console.log(`adding crop ${field.crop_id} to map`)
            removeFeaturesFromMap(mp.current.data)
            addFeaturesToMap(mp.current.data, field, drawSettings.plantedArea)
            mp.current.data.setMap(map)
            zoomMapLayer(map, mp.current.data)
            mp.current.crop_id = field.crop_id
            //map.setHeading(field.heading + 90)
            //}
        }

        return () => {
            //console.log('unmounting crop')
            removeFeaturesFromMap(mp.current.data)
            mp.current.data.setMap(null)
        }
    }, [field, map, drawSettings])

    React.useEffect(() => {
        if (task && task.method === 'planted-area' && field && map) {
            _drawPlantedArea(mp.current.data, field.planted_area)
        }
    }, [task, map, field])

    React.useEffect(() => {
        if (!task && map) {
            //console.log('removing task from map')
            //taskPolyline.setMap(null)
            removeFeaturesFromMap(task_checks.current)
            task_checks.current.setMap(null)
        }
        if (task && map) {
            startStop.current.begin.setMap(null)
            startStop.current.end.setMap(null)
            //console.log(`adding task ${task.id}-${task.method} to map`)
            removeFeaturesFromMap(task_checks.current)

            let previousName = null
            let line = []
            task.json_path.features.forEach((feature) => {
                if (feature.name != previousName && line.length) {
                    lines.push(createTaskLine(line, map, previousName))
                    previousName = feature.name
                    line = []
                }
                feature.geometry.coordinates.forEach((v) => {
                    line.push(new window.google.maps.LatLng(v[1], v[0]))
                })
            })
            lines.push(createTaskLine(line, map, previousName))
            //lines.forEach((line) => line.setMap(map))
            lines[0].icons.push({
                icon: startSymbol,
                offset: '0%',
            })
            lines.slice(-1)[0].icons.push({
                icon: endSymbol,
                offset: '100%',
            })

            if (task.checks.filter((c) => !c.success).length) {
                alerts.warning('Task contains boundary crosses')
            }

            task_checks.current.addGeoJson({
                type: 'FeatureCollection',
                features: task.checks,
            })
            task_checks.current.setMap(map)
        }
        return () => {
            //console.log('unmounting task')
            lines.forEach((line) => line.setMap(null))
            removeFeaturesFromMap(task_checks.current)
            task_checks.current.setMap(null)
        }
    }, [task, map])

    return null
}
