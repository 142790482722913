/* eslint-disable camelcase */
import { useState, ChangeEvent, useEffect } from 'react'
import { useAlertActions, useInferenceModelActions } from '../_actions'
import { SelectBox, StartStopTaskButton, RangeSlider } from '../_components'
import { CameraSettings } from '../types'
import { Skeleton, Box, Stack, Switch, FormControlLabel, LinearProgress, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useMachineSettingsActions } from '../_actions/machineSettings.actions'
import UserService from '../services/UserService'
import { ModelInfo } from './ModelInfo'
import SecurityIcon from '@mui/icons-material/Security'
import InfoIcon from '@mui/icons-material/Info'

interface InferenceModelSelectorProps {
    handleModalClose?: () => void
}

const getInitialSettings = (availableModels, machineSettings, isPrivileged) => {
    let camerasDefault = 'Super Groep v3'
    let robotDefault = 'Robocam_v08-02'

    // Check if value of machine settings camera model exists in available models of backend storage
    if (availableModels.cameras.includes(machineSettings.cameras_model)) {
        camerasDefault = machineSettings.cameras_model
    }

    // Check if value of machine settings robot model exists in available models of backend storage
    if (availableModels.robot.includes(machineSettings.robot_model)) {
        robotDefault = machineSettings.robot_model
    }

    return {
        cameras: {
            name: camerasDefault,
            rec_enabled: machineSettings.cameras_rec_enabled,
        },
        robot: {
            name: robotDefault,
            rec_enabled: machineSettings.robot_rec_enabled,
        },
        cluster_z_height_guess: isPrivileged ? machineSettings.cluster_z_height_guess : 0.1,
    }
}

export const InferenceModelSelector = ({ handleModalClose }: InferenceModelSelectorProps) => {
    const theme = useTheme()
    const isPrivileged = UserService.hasRole(['admin', 'manufacturer', 'dev'])

    // State
    const [modelOptions, setModelOptions] = useState({ cameras: [], robot: [] })
    const [settings, setSettings] = useState<CameraSettings>(null)

    // Actions
    const inferenceModelActions = useInferenceModelActions()
    const machineSettingsActions = useMachineSettingsActions()
    const alertActions = useAlertActions()

    useEffect(() => {
        Promise.all([inferenceModelActions.getModels(), machineSettingsActions.getLastMachineSettingsByMachineName()])
            .then(([availableModels, machineSettings]) => {
                setModelOptions(availableModels)
                setSettings(getInitialSettings(availableModels, machineSettings, isPrivileged))
            })
            .catch(() => {
                alertActions.error('Machine settings not found')
                handleModalClose()
            })
    }, [])

    function RecToggle(model: string) {
        return (
            <Box key={`${model}-box`} sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                    sx={{ mb: 0, mt: 0 }}
                    key={`${model}-form-control`}
                    disabled={false}
                    control={
                        <Switch
                            checked={settings[model].rec_enabled}
                            color={'info'}
                            onChange={() => {
                                setSettings({
                                    ...settings,
                                    [model]: {
                                        ...settings[model],
                                        rec_enabled: !settings[model].rec_enabled,
                                    },
                                })
                            }}
                        />
                    }
                    label={`${model[0].toUpperCase() + model.slice(1)} recording: ${
                        settings[model].rec_enabled ? 'enabled' : 'disabled'
                    }`}
                />
                {settings[model].rec_enabled && (
                    <Skeleton
                        key={`${model}-skeleton`}
                        variant='circular'
                        sx={{ bgcolor: theme.palette.error.dark }}
                        width={20}
                        height={20}
                    />
                )}
            </Box>
        )
    }

    const handleCameraSelectChangeEvent = (event: ChangeEvent<{ value }>) => {
        if (event.target.value.includes('Orange Disks') || event.target.value.includes('Led Strip')) {
            setSettings({
                ...settings,
                ['cameras']: {
                    ...settings['cameras'],
                    name: event.target.value,
                },
                ['robot']: {
                    ...settings['robot'],
                    name: event.target.value,
                },
            })
        } else {
            setSettings({
                ...settings,
                ['cameras']: {
                    ...settings['cameras'],
                    name: event.target.value,
                },
                ['robot']: {
                    ...settings['robot'],
                    name: 'Robocam_v08-02',
                },
            })
        }
    }

    const handleRobotSelectChangeEvent = (event: ChangeEvent<{ value }>) => {
        setSettings({
            ...settings,
            ['robot']: {
                ...settings['robot'],
                name: event.target.value,
            },
        })
    }

    const handleSliderChange = (val: number) => {
        setSettings({
            ...settings,
            cluster_z_height_guess: val,
        })
    }

    if (!settings) return <LinearProgress />
    return (
        <>
            <Box sx={{ mb: 2 }}>
                <SelectBox
                    inputLabel={'Selecteer een model'}
                    value={settings.cameras.name}
                    options={modelOptions.cameras}
                    disabled={false}
                    handleChange={handleCameraSelectChangeEvent}
                    width={'100%'}
                />
                <Stack direction={'row'} spacing={1} sx={{ mt: 1 }} alignItems={'center'}>
                    <InfoIcon fontSize='small' />
                    <Typography variant='h6'>Beschrijving</Typography>
                </Stack>
                {<ModelInfo modelName={settings.cameras.name} />}
            </Box>
            {isPrivileged && (
                <>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <SecurityIcon fontSize='small' />
                        <Typography variant='h6'>Admin Settings</Typography>
                    </Stack>
                    <SelectBox
                        inputLabel={'Selecteer een robot model'}
                        value={settings.robot.name}
                        options={modelOptions.robot}
                        disabled={false}
                        handleChange={handleRobotSelectChangeEvent}
                        width={'100%'}
                    />
                    {['cameras', 'robot'].map(RecToggle)}
                    <Box sx={{ mt: 1 }}>
                        <RangeSlider
                            title={'Cluster Z height'}
                            value={settings.cluster_z_height_guess}
                            unit={'meter'}
                            step={0.01}
                            min={0.0}
                            max={0.4}
                            handleChange={handleSliderChange}
                        />
                    </Box>
                </>
            )}
            <Box sx={{ float: 'right' }}>
                <StartStopTaskButton settings={settings} handleModalClose={handleModalClose} />
            </Box>
        </>
    )
}
