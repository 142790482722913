import { atom, selector } from 'recoil'
import { FollowPathStatus } from '../types'
import { treatmentAtom } from './treatment'

const followPathAtom = atom<FollowPathStatus | null>({
    key: 'follow_path',
    default: null,
})

const estimatedTimeLeftAtom = atom<number | null>({
    key: 'estimatedTimeLeft',
    default: null,
})

const followPathRunningSelector = selector<boolean | null>({
    key: 'follow_path_running',
    get: ({ get }) => get(followPathAtom)?.status === 3 || null,
})

const calculateTimeEstimate = (timeLeftInSeconds: number, speedControl: number): number => {
    if (speedControl == 1) {
        // Low
        return 2 * timeLeftInSeconds
    } else if (speedControl == 2) {
        // Auto
        return 1.333 * timeLeftInSeconds
    } else {
        // High and old machine software
        return timeLeftInSeconds
    }
}
const followPathCountDownSelector = selector<number | null>({
    key: 'follow_path_countdown',
    get: ({ get }) => {
        const timeLeftInSeconds = get(estimatedTimeLeftAtom)
        const speedControl = get(treatmentAtom)?.speed_control
        if (!timeLeftInSeconds || !speedControl) return null

        return calculateTimeEstimate(timeLeftInSeconds, speedControl)
    },
})

export { followPathAtom, followPathRunningSelector, followPathCountDownSelector, estimatedTimeLeftAtom }
