import { createTheme } from '@mui/material/styles'
const primaryLight = '#cdd8dd'
const secondaryLight = '#000000'
const infoLight = '#97a4ab'
const primaryDark = '#263238'
const secondaryDark = '#fff'
const infoDark = '#4f5b62'

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
        secondary: {
            main: secondaryLight,
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#343a40',
                },
            },
        },
    },
})

export const mobileDarkTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 400,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        mode: 'dark',
        primary: {
            main: primaryDark,
        },
        secondary: {
            main: secondaryDark,
        },
        info: {
            main: infoDark,
        },
    },
    components: {
        MuiToolbar: {
            styleOverrides: {
                dense: {
                    height: 64,
                    minHeight: 64,
                },
                root: {
                    backgroundColor: primaryDark,
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    // Controls default (unchecked) color for the thumb
                    color: primaryDark,
                },
                colorPrimary: {
                    '&.Mui-checked': {
                        // Controls checked color for the thumb
                        color: secondaryDark,
                    },
                },
                track: {
                    // Controls default (unchecked) color for the track
                    opacity: 0.2,
                    backgroundColor: infoDark,
                    '.Mui-checked.Mui-checked + &': {
                        // Controls checked color for the track
                        opacity: 0.7,
                        backgroundColor: infoDark,
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    backgroundColor: infoDark,
                    // '&.Mui-selected': {
                    //   backgroundColor: secondary,
                    // },
                },
            },
        },
    },
})
