import { Machine } from '../types'
import { useFetchWrapper } from '../_helpers'

export { useMachineActions }

function useMachineActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()

    return {
        getAll,
        getIP,
    }

    function getAll(): Promise<Machine[] | []> {
        return fetchWrapper.get(`${baseUrl}/machines`)
    }

    function getIP(machineName: string): Promise<string> {
        return fetchWrapper.get(`${baseUrl}/machines/${machineName}/ip`)
    }
}
